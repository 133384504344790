
import { defineComponent, reactive, ref } from 'vue';
import {
    listPagination, RequestData, getSearch,
    ListActionItem
} from '@/components/common/list';
import router, { installerBaseRouter, installerBatchRouter } from '@/router';
import changeTextByScene from '@/methods/setText';
import { communitySubRouter, communityBaseRouter } from '@/router/data';
import { payProcessing, cancel } from '@/components/view/common/payment';
import noticeBase from '@/util/notice.base';
import { getInitData } from './util';

export default defineComponent({
    components: {
        listPagination,
        payProcessing
    },
    setup() {
        changeTextByScene();

        const {
            headers,
            typeOptions,
            statusOptions
        } = getInitData();
        const requestData: RequestData = reactive({
            url: 'installgetorderlist',
            param: getSearch({
                Type: 'all',
                Status: 'all',
                Key: ''
            })
        });
        const updateToList = ref(0);
        const saveParamInPath = ref(false);
        const searchList = () => {
            updateToList.value += 1;
            saveParamInPath.value = true;
        };

        const isShowPayprocessing = ref(false);
        const payJumpUrl = ref('');
        const action: ListActionItem[] = [{
            type: 'pay',
            event(val: { bmurl: string }) {
                isShowPayprocessing.value = true;
                payJumpUrl.value = val.bmurl;
                window.open(val.bmurl);
            },
            class: 'el-icon-my-pay modify-icon',
            showCondition(val: { StatusEnum: string }) {
                return val.StatusEnum === '0';
            },
            title: WordList.OrderPay
        },
        {
            type: 'cancelpay',
            event(val: { OrderNumber: string; BmOrderNumber: string; PayCode: string }) {
                noticeBase.messageBox('confirm', WordList.TabelPaymentCancel, WordList.TabelDeleteTitle, 'warning')(() => {
                    cancel.cancelOrder({ OrderNumber: val.OrderNumber }, () => {
                        updateToList.value += 1;
                    });
                });
            },
            class: 'el-icon-my-cancel-payment cancelpay-icon',
            showCondition(val: { StatusEnum: string }) {
                return val.StatusEnum === '0';
            },
            title: WordList.TabelFootCancel
        }];

        const successPay = () => {
            isShowPayprocessing.value = false;
            updateToList.value += 1;
        };

        const goInfo = (value: { ID: string }) => {
            router.push(`/${communityBaseRouter}/${communitySubRouter.paymentinfo}?id=${value.ID}`);
        };
        const goBatchPayment = () => {
            router.push(`/${installerBaseRouter}/${installerBatchRouter.batchPayments}`);
        };

        return {
            typeOptions,
            requestData,
            statusOptions,
            headers,
            updateToList,
            saveParamInPath,
            searchList,
            isShowPayprocessing,
            action,
            successPay,
            goInfo,
            goBatchPayment,
            payJumpUrl
        };
    }
});
